import { BaseXHR } from '@utils/axios';
import { take, fork, select, call, put, takeLatest } from 'redux-saga/effects';
import { isServer } from '@utils/helper';
import {
  actions,
  CHECK_PROJECT_IS_VALID,
  GET_DETAIL_PROJECT,
  GET_INFO_USER,
} from './slice';
import { TENANT_DEFAULT } from './constants';

export function* watchAndLog() {
  while (true) {
    try {
      const styleConsole =
        'background: yellow; font-weight: bold; color: #40a9ff8c;';
      const action = yield take('*');
      const state = yield select();
      if (action.type !== '@@router/LOCATION_CHANGE') {
        console.group(action.type);
        console.log('%cBefore State', styleConsole, state);
        console.info('%cDispatching', styleConsole, action);
        console.log('%cNext State', styleConsole, state);
        console.groupEnd();
      }
    } catch {}
  }
}

export function* checkProjectIsValid({ payload }: any) {
  try {
    yield call(BaseXHR.$get, '/project/checkPublic', {
      slug: payload.slug,
      host: payload.host,
    });
    yield put(CHECK_PROJECT_IS_VALID.success());
  } catch (err) {
    yield put(CHECK_PROJECT_IS_VALID.failure());
  }
}

export function* getDetailProject({ payload }: any) {
  try {
    let url = '';

    try {
      const { data } = yield call(BaseXHR.$get, '/tenant/get-by-project', {
        id: payload.host || payload.domain || payload.id,
      });
      yield put(actions.setTenant({ ...TENANT_DEFAULT, ...data.data }));
    } catch (err) {
      yield put(actions.setTenant(TENANT_DEFAULT));
    }

    if (payload.isPayment) url = `project/getPaymentPublic?id=${payload.id}`;
    else if (payload.isPublic)
      url = `project/getPublic?${
        payload.domain
          ? `domain=${payload.domain}`
          : `slug=${payload.id}&host=${payload.host}`
      }`;
    else url = `project/getPreview?slug=${payload.id}`;
    const response = payload.isPublic
      ? yield call(BaseXHR.$get, url)
      : yield call(BaseXHR.$getWithAuth, url, {
          headers: {
            Authorization: `Bearer ${payload.token}`,
          },
        });
    const data = response.data.data || response.data;
    yield put(GET_DETAIL_PROJECT.success(data));
    // if (data?.scenes)
    //   yield put(actions.setListScene(data.scenes));
  } catch (err) {
    console.log(err);
    yield put(GET_DETAIL_PROJECT.failure());
  }
}

export function* getInfoUser({ payload }: any) {
  try {
    const { data } = yield call(BaseXHR.$getWithAuth, '/account/profile', {
      headers: {
        Authorization: `Bearer ${payload.token}`,
      },
    });

    yield put(GET_INFO_USER.success(data.data));
  } catch (err) {
    yield put(GET_INFO_USER.failure());
  }
}

const homeSagas: any = [
  takeLatest(GET_INFO_USER.TRIGGER, getInfoUser),
  takeLatest(GET_DETAIL_PROJECT.TRIGGER, getDetailProject),
  takeLatest(CHECK_PROJECT_IS_VALID.TRIGGER, checkProjectIsValid),
];

if (!isServer && process.env.NODE_ENV === 'development')
  homeSagas.push(fork(watchAndLog));

export default homeSagas;
