import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectListScene, selectSceneSelected } from '../selectors';
import { actions } from '../slice';
import { I_Scene } from '../types';

const useGeneralFunction = () => {
  const dispatch = useDispatch();
  const listScene = useSelector(selectListScene);
  const sceneSelected = useSelector(selectSceneSelected);

  const indexSceneSelected = useMemo(
    () => listScene.findIndex(item => sceneSelected?.id === item.id),
    [listScene, sceneSelected],
  );

  const nextScene = useMemo(
    () =>
      listScene.length
        ? listScene[
            indexSceneSelected === listScene.length - 1
              ? 0
              : indexSceneSelected + 1
          ]
        : undefined,
    [indexSceneSelected, listScene],
  );

  const onNextScene = useCallback(
    (_scene?: I_Scene) => {
      if (
        _scene &&
        // _scene.id !== sceneSelected?.id &&
        listScene.find(item => item.id === _scene.id)
      ) {
        dispatch(actions.setSceneSelected({ ..._scene, _id: _scene.id }));
        dispatch(actions.setprevSceneSelected(sceneSelected || null));
      }
    },
    [sceneSelected, listScene, dispatch],
  );

  return useMemo(
    () => ({
      nextScene,
      onNextScene,
    }),
    [nextScene, onNextScene],
  );
};

export default useGeneralFunction;
