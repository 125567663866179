import { createSelector } from '@reduxjs/toolkit';
import { selectDetailProject } from './detailProjectSelectors';
import { selectDomain } from './mainSelectors';

export const selectInfoUser = createSelector(
  [selectDomain],
  state => state.infoUser || undefined,
);

export const selectListCommentSceneSelected = createSelector(
  [selectDomain],
  state => state.listCommentSceneSelected || [],
);

export const selectIsOwnerTour = createSelector(
  [selectDetailProject, selectInfoUser],
  (project, user) => project?.user === user?.id,
);

export const selectIsAllowToAccessOnCollaborationMode = createSelector(
  [selectDomain],
  state => state.isAllowToAccessOnCollaborationMode,
);
