import { memo, useMemo } from 'react';
import { selectScriptChats } from '../selectors';
import { useSelector } from 'react-redux';
import { convertScriptChats } from '@utils/helper';
import dynamic from 'next/dynamic';

const DangerouslySetHtmlContent = dynamic(
  () => import('@components/DangerouslySetHtmlContent'),
);

const Scripts = memo(() => {
  const scriptChats = useSelector(selectScriptChats);

  const scriptsChatConverted = useMemo(
    () =>
      scriptChats && scriptChats?.length
        ? convertScriptChats(scriptChats)
        : undefined,
    [scriptChats],
  );

  return (
    <>
      {/* NOTE: Load scriptChats */}
      {!!scriptsChatConverted?.htmls && (
        <DangerouslySetHtmlContent html={scriptsChatConverted?.htmls} />
      )}
      {!!scriptsChatConverted?.scripts && (
        <DangerouslySetHtmlContent html={scriptsChatConverted?.scripts} />
      )}
    </>
  );
});

export default Scripts;
