import {
  COLOR_POPUP_BG_DEFAULT,
  COLOR_POPUP_TEXT_DEFAULT,
  CONFIG_THEME_COLOR_DEFAULT,
} from '@containers/Home/constants';
import { TypeThemeConfig } from '@containers/Home/types';
import { createGlobalStyle } from 'styled-components';

/* istanbul ignore next */
export const ThemeGlobal = createGlobalStyle<{
  selected: Exclude<TypeThemeConfig, null | undefined>['selected'];
  primaryColor?: string;
  secondaryColor?: string;
  textColor?: string;
  popupBgColor?: string;
  popupTextColor?: string;
}>`
  :root {
    --color-primary: ${props =>
      props.primaryColor ||
      CONFIG_THEME_COLOR_DEFAULT[props.selected].primaryColor};
    --color-primary--light: ${props =>
      props.primaryColor ||
      CONFIG_THEME_COLOR_DEFAULT[props.selected].primaryColor}80;
    --color-secondary: ${props =>
      props.secondaryColor ||
      CONFIG_THEME_COLOR_DEFAULT.custom1?.secondaryColor};
    --color-text: ${props =>
      props.textColor || CONFIG_THEME_COLOR_DEFAULT[props.selected].textColor};
    --color-popup-bg: ${props => props.popupBgColor || COLOR_POPUP_BG_DEFAULT};
    --color-popup-text: ${props =>
      props.popupTextColor || COLOR_POPUP_TEXT_DEFAULT};
    --color-icon: #fff;
  }

`;
