import {
  selectTitleProject,
  selectSceneSelected,
  selectMetaDataProject,
  selectHotspotSelected,
  selectGATrackingProject,
  selectGATagTrackingProject,
} from '@containers/Home/selectors';
import gaConfig from '@utils/ga';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useGoogleAnalytics = () => {
  const router = useRouter();
  const title = useSelector(selectTitleProject);
  const metadata = useSelector(selectMetaDataProject);
  const ga_tracking = useSelector(selectGATrackingProject);
  const ggtag_tracking = useSelector(selectGATagTrackingProject);
  const sceneSelected = useSelector(selectSceneSelected);
  const hotspotSelected = useSelector(selectHotspotSelected);

  const onUpdatePageViewByUrl = useCallback(() => {
    const pageTitle = `${sceneSelected?.title} | ${(
      metadata?.title ||
      title ||
      ''
    ).toUpperCase()}`;
    const pageLocation = metadata?.description || 'Panoee - Virtual Tour 360';
    const url = window.origin + router.asPath;
    if (ga_tracking)
      gaConfig().pageview(pageTitle, pageLocation, url, ga_tracking);
    if (ggtag_tracking)
      gaConfig().pageview(pageTitle, pageLocation, url, ggtag_tracking);
  }, [
    title,
    metadata,
    ga_tracking,
    sceneSelected,
    router.asPath,
    ggtag_tracking,
  ]);

  const onPushEventClickedHotspot = useCallback(() => {
    if (hotspotSelected) {
      const { title: hotspotName, type: hotspotType } = hotspotSelected;
      if (hotspotSelected.title)
        gaConfig().event({
          eventName: `clicked-hotspot-name-${hotspotName}`,
          sceneName: sceneSelected?.title || '',
          hotspotType,
          hotspotName,
        });
      gaConfig().event({
        eventName: `clicked-hotspot-type-${hotspotType}`,
        sceneName: sceneSelected?.title || '',
        hotspotType,
        hotspotName,
      });
    }
  }, [hotspotSelected, sceneSelected]);

  return useMemo(
    () => ({
      onUpdatePageViewByUrl,
      onPushEventClickedHotspot,
    }),
    [onUpdatePageViewByUrl, onPushEventClickedHotspot],
  );
};
