import { memo } from 'react';
import { useSelector } from 'react-redux';
import {
  selectConfigNadir,
  selectConfigPopupIntro,
  selectDetailFirstScene,
  selectPopupConfigGlobal,
  selectThemeConfigGlobal,
  selectTitleProject,
} from '../selectors';
import useGeneralFunction from '../hook/useGeneralFunction';
import { useTourEventState } from '../global/TourStats';
import dynamic from 'next/dynamic';
import { ThemeGlobal } from '@styles/theme-global';

const KrpanoBasic = dynamic(() => import('@components/Krpano/KrpanoBasic'), {
  ssr: false,
});

const ThemeWall = dynamic(() => import('@containers/Home/themes/ThemeWall'));
const ThemeSolid = dynamic(() => import('@containers/Home/themes/ThemeSolid'));
const ThemeDefault = dynamic(
  () => import('@containers/Home/themes/ThemeDefault'),
);
const ThemeBlank = dynamic(() => import('@containers/Home/themes/ThemeBlank'));
const ThemeBaseKrpano = dynamic(
  () => import('@containers/Home/themes/ThemeBaseKrpano'),
);
const ThemeCompact = dynamic(
  () => import('@containers/Home/themes/ThemeCompact'),
);

const Theme = memo(() => {
  const nadirConfig = useSelector(selectConfigNadir);
  const titleProject = useSelector(selectTitleProject);
  const firstScene = useSelector(selectDetailFirstScene);
  const themeConfig = useSelector(selectThemeConfigGlobal);
  const popupConfig = useSelector(selectPopupConfigGlobal);
  const configPopupIntro = useSelector(selectConfigPopupIntro);

  const { nextScene, onNextScene } = useGeneralFunction();
  const [tourEventStats] = useTourEventState('state');

  return titleProject ? (
    tourEventStats.isVisiblePopupIntro === false ||
    (tourEventStats.isVisiblePopupIntro !== undefined &&
      configPopupIntro.type === 'post' &&
      configPopupIntro.mode === 'fullscreen') ? (
      <>
        {themeConfig?.selected === 'folio' && (
          <ThemeCompact
            onChangeScene={onNextScene}
            onNextScene={onNextScene.bind(this, nextScene)}
          />
        )}
        {themeConfig?.selected === 'blank' && (
          <ThemeBlank
            onChangeScene={onNextScene}
            onNextScene={onNextScene.bind(this, nextScene)}
          />
        )}
        {themeConfig?.selected === 'wall' && (
          <ThemeWall
            onChangeScene={onNextScene}
            onNextScene={onNextScene.bind(this, nextScene)}
          />
        )}
        {themeConfig?.selected === 'basekrpano' && (
          <ThemeBaseKrpano
            onChangeScene={onNextScene}
            onNextScene={onNextScene.bind(this, nextScene)}
          />
        )}
        {themeConfig?.selected === 'custom1' && (
          <ThemeSolid
            onChangeScene={onNextScene}
            onNextScene={onNextScene.bind(this, nextScene)}
          />
        )}
        {(!themeConfig?.selected ||
          themeConfig?.selected === 'default' ||
          themeConfig?.selected === 'defaultNew') && (
          <ThemeDefault
            onChangeScene={onNextScene}
            onNextScene={onNextScene.bind(this, nextScene)}
          />
        )}

        <ThemeGlobal
          selected={themeConfig?.selected || 'default'}
          primaryColor={themeConfig?.config?.primaryColor}
          secondaryColor={
            themeConfig?.selected === 'custom1'
              ? themeConfig?.config?.secondaryColor
              : themeConfig?.config?.primaryColor
          }
          textColor={themeConfig?.config?.textColor}
          popupBgColor={popupConfig?.bgColor}
          popupTextColor={popupConfig?.textColor}
        />
      </>
    ) : (
      <KrpanoBasic
        scene={firstScene}
        urlTour={
          firstScene.media?.src_preview ||
          firstScene.media?.src_thumb ||
          firstScene.media?.src ||
          undefined
        }
        nadirConfig={nadirConfig}
        divId="krpanoContainer"
        krpanoId="krpanoId"
        styleContainer={{ width: '100vw', height: '100vh' }}
      />
    )
  ) : null;
});

export default Theme;
