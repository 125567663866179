import styled from 'styled-components';

export const CallToActionItemWrapper = styled.div<{
  scale: number;
}>`
  color: inherit !important;
  width: calc(42px * ${props => props.scale});
  height: calc(42px * ${props => props.scale});
  max-width: 120px;
  max-height: 120px;
  display: flex;
  align-items: center;
  padding: 0.75rem 0.5rem;
  cursor: pointer;
  transition: var(--transition-regular);
  border-radius: 8px;
  .--symbol {
    font-size: 1.25rem;
    margin-right: 0.5rem;
    object-fit: contain;
    display: inline-flex;
    justify-content: center;
  }
  .--arrow {
    position: absolute;
    right: 1rem;
    transform: translateX(1rem);
    opacity: 0;
    transition: var(--transition-smooth);
  }
  span {
    max-width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    left: 100%;
    transform: translateX(calc(42px * ${props => props.scale})) scale(0.9);
  }
  .--symbol {
    width: 80%;
  }
  i {
    font-size: calc(14px * ${props => props.scale}) !important;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: rgba(0, 0, 0, 0.35);
      .--arrow {
        opacity: 1;
        transform: none;
      }
    }
  }
`;

export const CallToActionsWrapper = styled.div<{
  offsetLeft: string | number;
  offsetRight: string | number;
  offsetBottom: string | number;
}>`
  position: absolute;
  bottom: ${props => props.offsetBottom || 165}px;
  &.--left {
    left: ${props => props.offsetLeft || 12}px;
    .--panel {
      left: 0;
    }
    &.--layoutList {
      .--panel {
        left: ${props => props.offsetLeft || 12}px;
      }
    }
    &.bubble {
      .itemLink {
        width: 100%;
        height: auto;
        padding-right: 18px;
        span {
          transform: none;
        }
        i {
          font-size: 1.25rem !important;
        }
      }
    }
  }
  &.--right {
    right: ${props => props.offsetRight || 12}px;
    .--panel {
      right: 0;
      transform-origin: bottom right;
    }
    &.--layoutList {
      .--panel {
        right: ${props => props.offsetRight || 12}px;
        ${CallToActionItemWrapper} {
          span {
            right: 0;
            transform: translateX(-42px) scale(0.9);
          }
        }
      }
    }
  }
  z-index: 1;
  .--toggler {
    width: 3.5rem;
    height: 3.5rem;
    font-size: 1.25rem;
    border-radius: 50%;
    background: var(--color-primary);
    backdrop-filter: blur(10px);
    color: var(--color-text);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i:nth-child(1) {
      display: block;
    }
    i:nth-child(2) {
      display: none;
    }
  }
  .--panel {
    position: absolute;
    bottom: 4.5rem;
    background: var(--color-primary);
    backdrop-filter: blur(10px);
    color: var(--color-text);
    width: max-content;
    display: flex;
    flex-flow: column;
    border-radius: 12px;
    padding: 0.5rem;
    min-width: 160px;
    transition: all 0.35s cubic-bezier(0.45, 1.83, 0.6, 0.97);
    opacity: 0;
    transform: scale(0.5);
    pointer-events: none;
    transform-origin: bottom left;
  }

  &.active {
    .--toggler {
      i:nth-child(1) {
        display: none;
      }
      i:nth-child(2) {
        display: block;
      }
    }
    .--panel {
      transform: none;
      opacity: 1;
      pointer-events: auto;
    }
  }
  &.--layoutList {
    .itemLink {
      span {
        position: relative;
        transform: none;
        left: 100%;
      }
    }
    .--toggler {
      display: none;
    }
    .--panel {
      display: flex;
      flex-flow: column;
      align-items: center;
      opacity: 1;
      transform: none;
      pointer-events: auto;
      border-radius: 0;
      background-color: transparent;
      backdrop-filter: initial;
      min-width: initial;
      top: 0;
      bottom: 0;
      margin: auto;
      position: absolute;
      height: max-content;
    }
    ${CallToActionItemWrapper} {
      background: var(--color-primary);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: var(--transition-smooth);
      margin: 0.5rem 0;
      span {
        position: absolute;
        padding: 4px 8px;
        border-radius: 4px;
        opacity: 0;
        font-weight: 600;
        pointer-events: none;
        transition: var(--transition-regular);
        text-shadow: 0 1px 6px rgb(0 0 0 / 35%);
      }
      .--symbol {
        margin: 0;
        font-size: 1rem;
        height: auto;
        object-fit: contain;
      }
      .--arrow {
        display: none;
      }
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background: var(--color-primary);
          transform: scale(1.1);
          span {
            opacity: 1;
          }
        }
      }
    }
  }
`;
